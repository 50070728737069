<template>
  <v-container class="white">
    <v-row>
      <v-col class="text-h6">
        {{ $trans("edit_category") }}
      </v-col>
    </v-row>
    <v-divider class="my-2" />
    <v-row>
      <v-col class="pa-0 ma-0">
        <v-form ref="form_edit_category" @submit.prevent="handleEdit">
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="name"
                    :rules="[rules.required, rules.maxChars(50)]"
                    :label="$trans('category_name')"
                    hide-details="auto"
                    outlined
                  />
                  <v-checkbox
                    v-model="status"
                    false-value="inactive"
                    true-value="active"
                    hide-details
                    :label="$trans('category_active')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="light"
              text
              :disabled="isLoading"
              @click="$emit('close')"
            >
              {{ $trans("cancel") }}
            </v-btn>
            <v-btn :disabled="isLoading" color="primary" text type="submit">
              {{ $trans("save") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import {
  maxChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "EditCategoryDialog",
  mixins: [sharedActions],
  data() {
    return {
      categoryCopy: null,
      isLoading: false,
      name: null,
      status: null,
      id: null,
      rules: {
        required,
        maxChars,
      },
    };
  },
  watch: {
    "getConfirmDialog.data": function () {
      this.fillForm();
    },
  },
  created() {
    this.fillForm();
  },
  methods: {
    ...mapActions({
      editCategory: "category/update",
    }),
    fillForm() {
      const category = JSON.parse(JSON.stringify(this.getConfirmDialog.data));
      this.categoryCopy = category;
      this.name = category.name;
      this.status = category.status;
      this.id = category.id;
    },
    handleEdit() {
      const isFormValid = this.$refs.form_edit_category.validate();
      if (isFormValid) {
        this.edit();
      } else {
        errorNotification("form_is_invalid", {}, false);
      }
    },
    async edit() {
      this.isLoading = true;
      this.$emit("loading", true);
      try {
        await this.editCategory({
          name: this.name,
          status: this.status,
          id: this.id,
        });
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
      } finally {
        this.$emit("loading", false);
        this.$emit("close");
      }
    },
  },
};
</script>
